import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Map from "../components/map"

import { Col, Image, Media, Button } from "react-bootstrap"

import CocinasImage from "../images/reigas_imgs_1_cocinas.png"
import PerneriaImage from "../images/reigas_imgs_1_Pernos.png"
import TuberiaFerreteriaImage from "../images/reigas_imgs_ferrre.png"

import ReguladorImage from "../images/regulador_prod.png"
import CocinaImage from "../images/Cocina-prod.png"
import ValvulasImage from "../images/valvulas-gas.png"
import TuberiaImage from "../images/tuberia.jpg"
import NervoMetalImage from "../images/nervometal.jpg"
import ToolsImage from "../images/cummings.jpg"
import AspersoresImage from "../images/aspersores.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Tuberías y Ferretería en general" />
    <Col>
      <h1>Tuberías y Ferretería en general</h1>
      <br />
      <div className="productItem">
        <Media>
          <img
            width={150}
            // height={150}
            className="mr-3"
            src={TuberiaImage}
            alt="Tuberías"
          />
          <Media.Body>
            <h2>Tuberías</h2>
            <p>
              Tuberías marca Rival, con calidad INEN, para desagüe,
              alcantarillado, agua potable, domiciliaria, ducto telefónico y sus
              respectivos accesorios.
              <br />
              <a
                href="https://drive.google.com/open?id=1OzNqCEnBspZ66lFQ27KTe52L6NetGPgb"
                target="blank"
              >
                <b>Consulte nuestro Catálogo de Tubería y accesorios.</b>
              </a>
            </p>
          </Media.Body>
        </Media>
      </div>

      <hr />

      <div className="productItem">
        <Media>
          <img
            width={150}
            // height={150}
            className="mr-3"
            src={AspersoresImage}
            alt="Aspersores Reigas"
          />
          <Media.Body>
            <h2>Aspersores Reigas</h2>

            <p>
              Ponemos a su disposición aspersores de 1/2, 3/4 y 1″. Cabeza Loca,
              Con Falda y Sin Falda entre otras.
              <br />
              <a
                href="https://drive.google.com/open?id=1rqWiaml2Wxvi9L6Rg7EcAcdfovbNorNz"
                target="blank"
              >
                <b>Consulte nuestro Catálogo de Aspersores marca Reigas.</b>
              </a>
            </p>
          </Media.Body>
        </Media>
      </div>

      <hr />

      <div className="productItem">
        <Media>
          <img
            width={150}
            // height={150}
            className="mr-3"
            src={ToolsImage}
            alt="Herramientas Manuales"
          />
          <Media.Body>
            <h2>Herramientas Manuales</h2>

            <p>
              Distribuimos la mundial-mente conocida marca Cummings Tool con 5
              años de garantía, para satisfacer sus exigentes necesidades.
              <br />
              <a
                href="https://drive.google.com/open?id=1hN2mKeYKuQ3LWgwXBK0uzyq4D5WjznSK"
                target="blank"
              >
                <b>Consulte nuestro Catálogo de Herramientas Cummings Tool.</b>
              </a>
            </p>
          </Media.Body>
        </Media>
      </div>

      <hr />

      <div className="productItem">
        <Media>
          <img
            width={150}
            // height={150}
            className="mr-3"
            src={NervoMetalImage}
            alt="Malla para Tumbado – NervoMetal"
          />
          <Media.Body>
            <h2>Malla para Tumbado – NervoMetal</h2>

            <p>
              Disponemos Stock permanente de Malla de excelente calidad y
              precio, ajustándonos a su presupuesto.
              <br />
              <a
                href="https://drive.google.com/open?id=1aW1MXm3cVbiSSWF_WsFRbAT_rDx5yDur"
                target="blank"
              >
                <b>
                  Consulte nuestro Catálogo de Mallas para tumbado – NervoMetal.
                </b>
              </a>
            </p>
          </Media.Body>
        </Media>
      </div>

      <br />
      <h2>Más Información</h2>
      <p>
        Consulte nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1aW1MXm3cVbiSSWF_WsFRbAT_rDx5yDur"
          target="blank"
        >
          <b>Catálogo de Mallas para tumbado – NervoMetal</b>
        </a>
        , nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1OzNqCEnBspZ66lFQ27KTe52L6NetGPgb"
          target="blank"
        >
          <b>Catálogo completo de Tubería y accesorios</b>
        </a>
        , nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1hN2mKeYKuQ3LWgwXBK0uzyq4D5WjznSK"
          target="blank"
        >
          <b>Catálogo de Herramientas Cummings Tool</b>
        </a>{" "}
        y nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1rqWiaml2Wxvi9L6Rg7EcAcdfovbNorNz"
          target="blank"
        >
          <b>Catálogo de Aspersores marca Reigas</b>
        </a>
        .
      </p>
      <p>
        También puede revisar nuestro{" "}
        <a
          href="https://drive.google.com/open?id=11yYtqHOatXqN6mUY5jWUN7tk1OFrpaeG"
          target="blank"
        >
          <b>Catálogo completo de Representaciones Reinoso</b>
        </a>
        , o visitar nuestra sala de exhibición. Póngase en contacto con
        nosotros, gustosos en Atenderle.{" "}
      </p>

      <p>
        <ul>
           
          <li>
            <b>Telf:</b> +593 07-4076831
          </li>
          <li>
            <b>Cel:</b> +593 099 537 1857
          </li>
        </ul>
      </p>
    </Col>
  </Layout>
)

export default IndexPage
